import React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/seo/seo"
import styled from "styled-components"
import MenuBody from "../pagesParts/menu/MenuBody"
import PageHeader from "../components/pageHeader/PageHeader"

const StyledMainWrapper = styled.main`
  margin-top: 60px;
`

export default function OurMenuPage() {
  return (
    <Layout>
      <SEO
        title="Nos Prestations"
        description="Nos prestations sont taillées à la mesure de vos besoins. Nous offrons des services de vente, d'installation, de maintenance ainsi que d'assistance et support informatique sur-mesure. N'hésitez pas à prendre rendez-vous pour un entretien personnalisé."
      />
      <StyledMainWrapper>
        <PageHeader
          heading="Nos Prestations"
          subheading="Nos prestations sont taillées à la mesure de vos besoins. Nous offrons des services de vente, d'installation, de maintenance ainsi que d'assistance et support informatique sur-mesure. N'hésitez pas à prendre rendez-vous pour un entretien personnalisé."
        />
        <MenuBody />
      </StyledMainWrapper>
    </Layout>
  )
}
